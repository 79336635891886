
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$parking-ui-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$parking-ui-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A400);

// The warn palette is optional (defaults to red).
$parking-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$parking-ui-theme: mat.m2-define-light-theme((
  color: (
    primary: $parking-ui-primary,
    accent: $parking-ui-accent,
    warn: $parking-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-legacy-component-themes($parking-ui-theme);
@include mat.all-component-themes($parking-ui-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; overflow: auto !important; }

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import 'sass/themes.scss';

snack-bar-container.alert-red {
  padding: 20px;
  background-color: red;
  color: white;
}

snack-bar-container.alert-red button {
  background:white;
  color: red;
}

.mat-tooltip {
  font-size: 15px !important;
  padding: 10px 15px !important;
}

.mat-step-header {
  background: none !important;
  &:hover {
    background-color: none;
    .mat-step-label {
      color: #256EC1;
    }
  }
}
.nav-button-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  @media only screen and (max-width: $breakpoint-lg) {
    display: inline;
    white-space: nowrap !important;
  }
}

.backButton {
  background: none;
  color: $blue-6 !important;
  font-weight: bold !important;
  padding: 0;
  @media (max-width: $phone-max-width) {
   margin-left: 0;
 }
}


.nextButton {
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: $gray-3 !important;
    color: $gray-6;
  }
  &:enabled {
    background: $blue-6 !important;
    color: $white !important;
  }

  @media (max-width: $phone-max-width) {
    margin-right: 0;
  }
 }

.add-button-container {
  display: flex;
  padding: 15px 0px 30px 0px;

  .addButton {
    width: 175px;
    height: 50px;
    border-radius: 2px;
    color: black;
    background: $white;
    border: 1px solid black;
    @media only screen and (max-width: $breakpoint-lg) {
     width: 100%;
    }
   }
}

button {
  width: 175px;
  height: 50px !important;
  border-radius: 2px;
  font-weight: 500;
  color: white;
  background: $blue-6;
  border: none;
  @media only screen and (max-width: $breakpoint-lg) {
    width: 100%;
  }

  /**
  Added visually hidden style to add new accessibility areas to the course reg application.
 */
  .visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}
