// Our custom breakpoints - in addition to the bootstrap 4 ones defined in bootstrap and our EBreakpoint enum file
// Try to use the bootstrap breakpoints before resorting to custom ones
// If these are ever changed / added to / removed from, be sure to also update the karma-viewport config (karma.conf)
// to match so that tests are kept in sync with the app's config
$phone-max-width: 415px;
$phone-sm-width: 550px;
$breakpoint-lg: 850px;
$navbarBreakpoint-md: 700px;
$navbarBreakpoint-lg: 850px;
$navbarBreakpoint-xl: 950px;
$tablet-max-width: 1105px;
$vehicleListBreakpoint-md: 664px;
$vehicleListBreakpoint-sm: 440px;

$blue-1: #0080D0; //BLUE decal type icon
$blue-2: #0060B0; //BLUE decal type icon
$blue-3: #0B1733; //header bar
$blue-4: #3486EA; //header bar h1
$blue-5: #d1e5fd; //impersonate bar
$blue-6: #256EC1; //primary button background, checkbox border, checkmark, link text, secondary button text
$blue-10: #a7c7ec; //proxy active courses background

$gray-1: #999999; //UNKOWN decal type icon
$gray-2: #777777; //UNKOWN decal type icon
$gray-3: #D3D3D3; //navbar border bottom, configuration border bottom
$gray-4: #767676; //select border
$gray-6: #ABABAB; //times on calendar grid

$green-1: #024b30; //select decals valid text
$green-2: #11C161; //GREEN decal type icon
$green-3: #00A040; //GREEN decal type icon

$ltGreen-1: #B4F272; //LTGREEN decal type icon
$ltGreen-2: #92D050; //LTGREEN decal type icon

$cyan-1: #55EEEE; //CYAN decal type icon
$cyan-2: #33CCCC; //CYAN decal type icon

$yellow-1: #FFFF44; //YELLOW decal type icon
$yellow-2: #FFFF00; //YELLOW decal type icon

$red-1: #FFAAAA; //Inactive decal type
$red-2: #E22222; //RED decal type icon, validation error text and border
$red-3: #C00000; //RED decal type icon

$gold-1: #FFBB00; //GOLD decal type icon
$gold-2: #DD9900; //GOLD decal type icon

$orange-1: #FFE222; //ORANGE decal type icon
$orange-2: #FFC000; //ORANGE decal type icon

$brown-1: #AA7744; //BROWN decal type icon
$brown-2: #885522; //BROWN decal type icon

$purple-1: #9a4cbe; //PURPLE decal type icon
$purple-2: #773496; //PURPLE decal type icon

$white: #FFFFFF; //mobile body background, modal background, landing/search page content background, secondary button background, even striping background, form background, primary button text, navbar background, tooltip text

$black-1: #000000; //inactive link text, BLACK decal type icon
$black-2: #444444; //BLACK decal type icon
